import React from 'react';
import { Script } from 'nordic/script';
import PropTypes from 'prop-types';
import { siteTitle, suScopePath } from '../helpers';
import { floxBrowserView } from '../flox/flox-browser-view';

const Home = (props) => {
  const { floxPreloadedState } = props;
  let { FloxView } = props;
  if (!FloxView) FloxView = floxBrowserView(floxPreloadedState);

  return (
    <>
      <Script on="now">{suScopePath}</Script>
      <FloxView />
    </>
  );
};

Home.propTypes = {
  FloxView: PropTypes.elementType,
  floxPreloadedState: PropTypes.shape({}),
  platform: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  device: PropTypes.shape({}),
};

export const hydrate = 'hydrateRoot';

export default Home;

export const getServerSideProps = async (req, res) => {
  const { platform } = req;
  const { floxPreloadedState, FloxView, clientEvents } = res.locals.state;

  return {
    props: {
      floxPreloadedState,
      FloxView,
      clientEvents,
    },
    settings: {
      title: siteTitle(platform.siteId),
    },
  };
};

export const setPageSettings = ({ settings }) => ({
  title: settings.title,
  className: 'home-page',
});
